import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import {DataProvider, componentMap} from "../contentful/data-provider";
import ogimage from "../images/ogimages/validators.png";
import {Helmet} from "react-helmet";

export default function Validators({data}) {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Validators',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Validators',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Validators</title>
            </Helmet>
            <div className={'validators-page'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    return (
                        <>
                            {React.createElement(
                                componentMap[section.type],
                                {content: dataProvider.getElementById(section.id), key: index}
                            )}
                        </>
                    );
                }) }
            </div>
        </Layout>
    );
}

export const query = graphql`
query {
  contentfulPage(id: {eq: "0731d2c1-851c-59c2-ac9e-afa7dc690322"}) {
    ... CurrentPageDetails
  }
  allContentfulEntry {
    edges {
      node {
        ... AllEntryDetails
      }
    }
  }
}
`;
